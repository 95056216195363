.App {
  font-family: 'Nunito Sans', sans-serif;
  text-align: center;
  background:white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.create-area {
  background-color:#f9f9f9;
  max-width:800px;
  margin:auto;
  padding:10px 25px 8px 25px;
  border-radius: 0.3em;
  border: 1px solid #f0f0f0;
  margin-top:40px;
}
.create-area h1 {
  text-align: left;
  font-size:1.3em;
  padding-top: 0;
}
.user-n-title {
  display:flex;
}
#user-name {
  float:left;
  width:25%;
  font-size:1.2em;
  font-weight: 200;
  margin:1px;
  border: 1px solid #f0f0f0;
  padding:7px;
}
#title {
  float:right;
  width:75%;
  font-size:1.2em;
  font-weight: 200;
  margin:1px;
  border: 1px solid #f0f0f0;
  padding:7px;
}
.content textarea{
  width:784px;
  height: 100px;
  font-size:1.1em;
  font-weight: 600;
  margin:1px;
  border: 1px solid #f0f0f0;
  padding:6px;
  font-family: 'Nunito Sans', sans-serif;
}
input:focus {
  outline-color: grey;
  outline-style:groove;
}
textarea:focus {
  outline-color: grey;
  outline-style:groove;
}
.button{
  display: flex;
  justify-content: right;
}
#button {
  display: inline-block;
  padding: 7px 15px;
  margin-top:5px;
  font-size: 1.2em;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #08a0e9;
  border: none;
  border-radius: 5px;
}
#button:hover {
  background-color: #00b7ff;
}






.posts {
  background-color:#f5f5f5;
  max-width:800px;
  margin:auto;
  padding:0 25px;
  border-radius: 0.3em;
  border: 1px solid #f0f0f0;
  margin-top:3px;
}
.posts h1 {
  text-align: left;
  font-size:1.3em;
}
.post {
  display:flex;
}
#post-user {
  float:left;
  width:25%;
  font-size:1.2em;
}
#post-body {
  float:right;
  width:75%;
}

#post-user {
  text-align: left;
}
#post-title {
  text-align:left;
  font-weight: 600;
}
#post-content {
  text-align:left;
  font-weight: 400;
  font-size:1.1em;
}
#post-time {
  font-size:0.7em;
}